import { Button, Container, Fab, Grid, Paper, Typography, Box } from "@mui/material";
import React, { useState } from "react";
// import styles from '../../styles.module.css';
import Imghome from '../../images/home-img-1.jpg'
import Imghome2 from '../../images/home-img-3.jpg'
import MessageIcon from '@mui/icons-material/Message';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import ChatBord from "../chatbord/ChatBord";
import '../../index.css';

const CustomTab = styled(Tab)(({ theme }) => ({
    minWidth: '9px',
    minHeight: '8px',
    fontWeight: '800',
    fontSize: '23px',
    color: '#403f40',
    borderRadius: '6px 6px 0px 0px',
    border: '1px solid #e6e8eb',
    backgroundColor: '#ffffff',
    padding: '14px 6px 14px 6px',
    margin: '12px 12px 12px 1px',
    letterSpacing: '0.3px',
    wordSpacing: '0.8px',
    textTransform: 'none',

    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 10px 100px -10px, rgba(0, 0, 0, 0.3) 0px 10px 10px -30px',
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const watermarkText = "Further, you can convert your estimates and quotations into sale invoices anytime. All you need is the Vyapar software for billing and its done in a few clicks. Vyapar free billing software offers the business a complete option for quickly saving more time getting instant quotes. Using the accounting software, you can manage your business with higher productivity.";
const maxWidth = 1200; // SVG width

const Home = () => {


    const lines = [];
    let words = watermarkText.split(' ');
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
        const testLine = currentLine + ' ' + words[i];
        if (testLine.length * 10 <= maxWidth) { // Assuming average character width is 10
            currentLine = testLine;
        } else {
            lines.push(currentLine);
            currentLine = words[i];
        }
    }

    lines.push(currentLine); // Add the last line


    const [chatOpen, setChatOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleChatToggle = (event) => {
        setAnchorEl(event.currentTarget);
        setChatOpen(true);
    };

    const handleChatClose = () => {
        setAnchorEl(null);
        setChatOpen(false);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Paper className='paper_content'>
                <Container className='cube_container'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography className='heading'>GST Billing Software in India for Small Businesses</Typography>

                            <Typography className='conten_p'> Manage your business professionally with Vyapar.
                                Using the best software for your billing,
                                inventory & accounting needs. Be a part of 1 Cr+ SMEs in India who trust Vyapar.</Typography>
                            <div className='myComponent'>
                                <Button className='action' variant="contained"> Check Open Position</Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{
                            backgroundImage: `url(${Imghome})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}>


                        </Grid>
                    </Grid>
                </Container>

                <Container className='cube_container'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} style={{
                            backgroundImage: `url(${Imghome2})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}>


                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography className='heading' sx={{ textAlign: 'center' }}>Create GST Bills for customers and share them online</Typography>
                            <Typography className='conten_p'> Join our teams and have the freedom to do your best work.
                                We are building a culture with amazing people like you at its centre.
                                Your work here will help millions of small businesses succeed & grow </Typography>
                            <Button className='action' variant="contained"> Check Open Position</Button>
                        </Grid>
                    </Grid>
                </Container>
                <Container className='cube_container'>
                    <Typography className='heading' sx={{ textAlign: 'center' }}> Features of GST Billing and Accounting Software</Typography>
                    <Box sx={{ width: '100%' }}>
                        <Box
                            sx={{ flexGrow: 1, bgcolor: 'background.paper', }}
                        >

                            <Tabs
                                orientation="horizantal"
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                            // sx={{ borderRight: 1, borderColor: 'divider',  }}
                            >
                                <CustomTab label="Sent Estimate & Quotations" {...a11yProps(0)} />
                                <CustomTab label="Track Orders" {...a11yProps(1)} />
                                <CustomTab label="Choose Themes" {...a11yProps(2)} />
                                <CustomTab label="Record Expenses" {...a11yProps(3)} />
                                <CustomTab label="Receivables and Payables" {...a11yProps(4)} />
                                <CustomTab label="Delivery Challan" {...a11yProps(5)} />
                                <CustomTab label="Bank Accounts" {...a11yProps(6)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                {/* <div style={{ width: '800px' }}> */}
                                <Typography className='tab_details_title'>  Send Estimate & Quotations </Typography>
                                <Typography className='tab_details_p'>Using our free billing software, you can easily create useful documents. It includes quotations, estimates, and accurate GST invoices. Inbuilt features in the GST billing app allow you to send quotes/estimates to customers anytime. You can send them directly through WhatsApp, email, SMS, or by printing. </Typography>
                                <Typography className='tab_details_p'> Vyapar billing software provides professional outlook with instant estimates and quotes. The Vyapar app helps automate most processes. It makes the quotes and estimates error-free. Additionally, you can set up a due date for tracking invoices seamlessly. </Typography>
                                <Typography className='tab_details_p'>Further, you can convert your estimates and quotations into sale invoices anytime. All you need is the Vyapar software for billing and its done in a few clicks. Vyapar free billing software offers the business a complete option for quickly saving more time getting instant quotes. Using the accounting software, you can manage your business with higher productivity. </Typography>
                                <Typography className='tab_details_p'> The billing app brings a professionalism for your valued customers to attract them back. Choosing advanced Software for GST billing is one of the best investments for your business. Our Billing Software helps simplifies estimates and quotations.</Typography>
                            </TabPanel>
                            <TabPanel value={value} index={1}>

                                <Typography className='tab_details_title'> Track Orders </Typography>
                                <Typography className='tab_details_p'>Using our advanced GST Compliant Software for Billing makes creating sales or purchase orders easier. It helps set up a due date for tracking order seamlessly. With this GST Accounting and Billing Software, we get an auto stock adjustment. It help ensure the availability of inventory items. </Typography>
                                <Typography className='tab_details_p'> Having tracking features is pretty helpful for fulfilling the orders timely. Tracking avoid unnecessary losses. You can save time with tracking and use it to perform other day to day tasks. It enables better customer satisfaction. You can attach tax invoice with order and track if payment is due using the app.</Typography>
                                <Typography className='tab_details_p'>Using our GST billing app, it is easier to improve your purchase/sale order formats. As Vyapar App provides various options, including Word, PDF, and Excel. Using our GST invoicing software, you can save labour cost and efforts. You can do save time by converting orders to sale/purchase invoices using automation. </Typography>
                                <Typography className='tab_details_p'> You can track open, close, and overdue orders using our free billing app. It makes it a perfect choice for businesses. Using these features makes the entire tracking process seamless for businesses. In short, it helps improve the performance of employees. </Typography>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Typography className='tab_details_title'> Choose Themes </Typography>
                                <Typography className='tab_details_p'> Maintaining and sharing professional invoices with your clients can improve your brand’s Identity. The GST Billing App has two invoice themes for thermal printers. Also, it has twelve invoice themes for regular printers. </Typography>
                                <Typography className='tab_details_p'> With this GST invoice software, you can quickly improve the look of your invoice Using available customisation options is seamless. You can neatly prepare the invoice for your client. Business bills generated can better impress a client. </Typography>
                                <Typography className='tab_details_p'> The Billing App for GST is the best option for your accounting inventory. It is very easy to handle. Choose from the best GST invoice formats to meet your business requirements. Most businesses choose our free billing software to help present a professional image. It is an ideal way to build a positive brand image. </Typography>
                                <Typography className='tab_details_p'> This free billing app is efficient for quickly gaining the highest invoice standard. It provides multiple theme options for thermal and regular printers. GST Invoicing Software is suitable, and all themes are fully customisable. A business can use it for all commercial businesses. The list includes retailers, pharmaceutical businesses, gyms, restaurants, and many other businesses. </Typography>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Typography className='tab_details_title'> Record Expenses </Typography>
                                <Typography className='tab_details_p'> Maintaining and sharing professional invoices with your clients can improve your brand’s Identity. The GST Billing App has two invoice themes for thermal printers. Also, it has twelve invoice themes for regular printers. </Typography>
                                <Typography className='tab_details_p'> With this GST invoice software, you can quickly improve the look of your invoice Using available customisation options is seamless. You can neatly prepare the invoice for your client. Business bills generated can better impress a client. </Typography>
                                <Typography className='tab_details_p'> The Billing App for GST is the best option for your accounting inventory. It is very easy to handle. Choose from the best GST invoice formats to meet your business requirements. Most businesses choose our free billing software to help present a professional image. It is an ideal way to build a positive brand image. </Typography>
                                <Typography className='tab_details_p'> This free billing app is efficient for quickly gaining the highest invoice standard. It provides multiple theme options for thermal and regular printers. GST Invoicing Software is suitable, and all themes are fully customisable. A business can use it for all commercial businesses. The list includes retailers, pharmaceutical businesses, gyms, restaurants, and many other businesses. </Typography>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <Typography className='tab_details_title'> Receivables and Payable </Typography>
                                <Typography className='tab_details_p'> Maintaining and sharing professional invoices with your clients can improve your brand’s Identity. The GST Billing App has two invoice themes for thermal printers. Also, it has twelve invoice themes for regular printers. </Typography>
                                <Typography className='tab_details_p'> With this GST invoice software, you can quickly improve the look of your invoice Using available customisation options is seamless. You can neatly prepare the invoice for your client. Business bills generated can better impress a client. </Typography>
                                <Typography className='tab_details_p'> The Billing App for GST is the best option for your accounting inventory. It is very easy to handle. Choose from the best GST invoice formats to meet your business requirements. Most businesses choose our free billing software to help present a professional image. It is an ideal way to build a positive brand image. </Typography>
                                <Typography className='tab_details_p'> This free billing app is efficient for quickly gaining the highest invoice standard. It provides multiple theme options for thermal and regular printers. GST Invoicing Software is suitable, and all themes are fully customisable. A business can use it for all commercial businesses. The list includes retailers, pharmaceutical businesses, gyms, restaurants, and many other businesses. </Typography>
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <Typography className='tab_details_title'> Delivery Challan </Typography>
                                <Typography className='tab_details_p'> Maintaining and sharing professional invoices with your clients can improve your brand’s Identity. The GST Billing App has two invoice themes for thermal printers. Also, it has twelve invoice themes for regular printers. </Typography>
                                <Typography className='tab_details_p'> With this GST invoice software, you can quickly improve the look of your invoice Using available customisation options is seamless. You can neatly prepare the invoice for your client. Business bills generated can better impress a client. </Typography>
                                <Typography className='tab_details_p'> The Billing App for GST is the best option for your accounting inventory. It is very easy to handle. Choose from the best GST invoice formats to meet your business requirements. Most businesses choose our free billing software to help present a professional image. It is an ideal way to build a positive brand image. </Typography>
                                <Typography className='tab_details_p'> This free billing app is efficient for quickly gaining the highest invoice standard. It provides multiple theme options for thermal and regular printers. GST Invoicing Software is suitable, and all themes are fully customisable. A business can use it for all commercial businesses. The list includes retailers, pharmaceutical businesses, gyms, restaurants, and many other businesses. </Typography>
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                <Typography className='tab_details_title'> Bank Accounts </Typography>
                                <Typography className='tab_details_p'> Maintaining and sharing professional invoices with your clients can improve your brand’s Identity. The GST Billing App has two invoice themes for thermal printers. Also, it has twelve invoice themes for regular printers. </Typography>
                                <Typography className='tab_details_p'> With this GST invoice software, you can quickly improve the look of your invoice Using available customisation options is seamless. You can neatly prepare the invoice for your client. Business bills generated can better impress a client. </Typography>
                                <Typography className='tab_details_p'> The Billing App for GST is the best option for your accounting inventory. It is very easy to handle. Choose from the best GST invoice formats to meet your business requirements. Most businesses choose our free billing software to help present a professional image. It is an ideal way to build a positive brand image. </Typography>
                                <Typography className='tab_details_p'> This free billing app is efficient for quickly gaining the highest invoice standard. It provides multiple theme options for thermal and regular printers. GST Invoicing Software is suitable, and all themes are fully customisable. A business can use it for all commercial businesses. The list includes retailers, pharmaceutical businesses, gyms, restaurants, and many other businesses. </Typography>
                            </TabPanel>
                        </Box>
                    </Box>
                </Container>
                <Container >
                

                </Container>
                {/* <div>
                    <div className={styles.cube_chat}>
                        <Fab color="primary" aria-label="add" onClick={handleChatToggle}>
                            <MessageIcon sx={{ fontSize: '38px' }} />
                        </Fab>
                    </div>
                    <ChatBord open={chatOpen} onClose={() => setChatOpen(false)} />
                </div> */}
                <div>
                    <div className='cube_chat'>
                        <Fab color="primary" aria-label="add" onClick={handleChatToggle}>
                            <MessageIcon sx={{ fontSize: '38px' }} />
                        </Fab>
                    </div>
                    <ChatBord open={chatOpen} anchorEl={anchorEl} onClose={handleChatClose} />
                </div>
            </Paper>
        </>
    )

}
export default Home;