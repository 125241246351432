import { AppBar, Button, Container, Grid, Paper, Toolbar, Typography } from "@mui/material";
import React from "react";
// import styles from '../../styles.module.css';

const B3Footer = () => {
  return (
    <>

      <AppBar position="static" color="primary" sx={{ bottom: 0 }}>
        <Container maxWidth="lg">
          {/* <Toolbar> */}
          <Typography variant="body1" color="inherit" textAlign="center" sx={{ fontSize: '14px', paddingTop: '5px', paddingBottom: '8px' }}>
            &copy; 2023 OneNet Solutions Pvt Ltd. All rights reserved.
          </Typography>
          {/* </Toolbar> */}
        </Container>
      </AppBar>
    </>
  )

}
export default B3Footer;