
import React, { useEffect, useState, forwardRef, useRef } from "react";
// import ReactToPrint, { PrintContextConsumer, useReactToPrint } from "react-to-print";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
    Box, Button, Grid, Dialog, DialogTitle, TextField, DialogActions, Container, Popover,
    TablePagination, Typography, InputAdornment, IconButton, Tooltip, DialogContent, Divider, Snackbar, TableCell, TableRow
} from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import ViewArrayIcon from '@mui/icons-material/ViewArray';
// import { downloadExcel } from 'react-export-table-to-excel';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CloseIcon from '@mui/icons-material/Close';


const studentData = [
    { name: 'John Doe', faculty: 'Science', seatNo: 'S001' },
    { name: 'Jane Smith', faculty: 'Arts', seatNo: 'S002' },
    { name: 'Bob Johnson', faculty: 'Engineering', seatNo: 'S003' },
    { name: 'John Doe2', faculty: 'Science', seatNo: 'S004' },
    { name: 'Jane Smith3', faculty: 'Arts', seatNo: 'S005' },
    { name: 'Bob Johnson4', faculty: 'Engineering', seatNo: 'S006' },
    { name: 'John Doe5', faculty: 'Science', seatNo: 'S007' },
    { name: 'Jane Smith6', faculty: 'Arts', seatNo: 'S008' },
    { name: 'Bob Johnson7', faculty: 'Engineering', seatNo: 'S009' },
];
function Careers(props) {
    const [successMessage, setSuccessMessage] = useState(null);

    let ref = useRef();

    const [openPopup, setOpenPopup] = React.useState(false);
    const [info, setInfo] = useState({});

    const tableRef = useRef(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(0)
    const [open, setOpen] = React.useState();

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }


    const [isDelete, setIsDelete] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState();

    const header = ["Bin Type",];
    const body = [];

    const deleteRecord = (id) => {
        // dispatch(editDeleteBin({ id: deleteInfo.id, modifiedBy: userInfo.userId, modifiedAt: dateConversionOnEntryPage(new Date()), changedUpdatedValue: 'delete' }))
        setIsDelete(false);
    }

    const handleDelete = (functions) => {
        setIsDelete(true);
        setDeleteInfo(functions);
        setOpen(false);
    }

    const handleClose = (event, reason) => {
        setIsDelete(false)
        setDeleteInfo(null);
        setOpen(false);
        setOpenPopup(false);
    };



    return (
        <>

            <Paper sx={{boxShadow:'none', margin:'10px'}}>
                <Box>
                    <Typography sx={{fontSize:'25px', fontWeight:'bold'}}>Students List </Typography>
                </Box>
                <TableContainer component={Paper} >
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ fontWeight: 800, backgroundColor: '#bfbfbf' }}>Action</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 800, backgroundColor: '#bfbfbf' }}>Student Name </TableCell>
                                <TableCell align="center" sx={{ fontWeight: 800, backgroundColor: '#bfbfbf' }}>Faculty </TableCell>
                                <TableCell align="center" sx={{ fontWeight: 800, backgroundColor: '#bfbfbf' }}>Seat No</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {studentData && studentData

                                .map((row, index) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center" >
                                            <PopupState variant="popover" popupId="demo-popup-popover">
                                                {(popupState) => (
                                                    <div>
                                                        <MoreHorizIcon
                                                            // id={styles.actionBtn}
                                                            alt="translator"
                                                            variant="contained"
                                                            {...bindTrigger(popupState)}
                                                        />
                                                        <Popover
                                                            {...bindPopover(popupState)}
                                                            anchorOrigin={{
                                                                vertical: "right",
                                                                horizontal: "center"
                                                            }}
                                                            transformOrigin={{
                                                                vertical: "right",
                                                                horizontal: "center"
                                                            }}
                                                        >
                                                            <Container >
                                                                <>
                                                                    <NavLink to={{ pathname: `/about` }} state={row} >
                                                                        <Button >
                                                                            <ViewArrayIcon style={{ float: 'left', fontSize: '20px', cursor: 'pointer' }} />
                                                                            <span style={{ fontSize: '12px', }}> Genrate Marksheet</span> </Button>
                                                                    </NavLink>
                                                                </>
                                                            </Container>
                                                        </Popover>
                                                    </div>
                                                )}
                                            </PopupState>
                                        </TableCell>
                                        <TableCell align="center">{row.name}</TableCell>
                                        <TableCell align="center">{row.faculty}</TableCell>
                                        <TableCell align="center">{row.seatNo}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    sx={{ px: 2 }}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={studentData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Dialog open={isDelete}
                PaperProps={{
                    sx: {
                        minWidth: "35%",
                        minHeight: "22%",
                        borderRadius: '8px'
                    }
                }}>
                <DialogTitle >
                    Confirm Delete The Record?
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            right: 0,
                            top: 0,
                            float: 'right'
                        }} >
                        <CloseIcon style={{ height: '18px', color: '#000000' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent >
                    The marksheet Portal will remove this record # {deleteInfo && deleteInfo}
                </DialogContent>
                <DialogActions sx={{ padding: '10px', }}>
                    <Button onClick={handleClose} variant='outlined'>
                        No
                    </Button>
                    <Button onClick={() => deleteRecord(deleteInfo.id)} variant='outlined'>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default Careers