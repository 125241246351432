import { Route, Routes } from "react-router-dom";
// import Home from "../home/Home";
import B3Header from "../header/B3Header";
import B3Footer from "../footer/B3Footer";
import Home from "../home/Home";
import About from "../about/About";
import Careers from "../career/Careers";
// import About from "../about/About";


export default function AllRoutes() {
    
    return (
        <>
          <Routes>
          {/* <Route path="/" element={<> <B3Header /> <Home/> <B3Footer/> </>}  ></Route> */}
          <Route path="/about" element={<> <B3Header /> <About /> <B3Footer/> </>} ></Route>
          <Route path="/" element={<> <B3Header /> <Careers /> <B3Footer/> </>} ></Route>
          </Routes>
        </>
    );
}