// import logo from './logo.svg';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AllRoutes from './pages/routes/AllRoutes';

function App() {
  return (
    <div> 
<BrowserRouter> 
      <AllRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
